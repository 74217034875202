const i18n = {
  'en-US': {},
  'zh-CN': {
    'menu.home': '首页',
    'menu.dashboard': '仪表盘',
    'menu.dashboard.workplace': '统计',
    'menu.goods': '商品管理',
    'menu.goods.list': '商品列表',
    'menu.order': '订单管理',
    'menu.order.list': '订单列表',
    'menu.account': '员工管理',
    'menu.account.list': '员工列表',
    'menu.settings': '用户设置',
    'navbar.logout': '退出登录'
  }
};
export default i18n;