import { useEffect, useState } from 'react';
import { isSSR } from '@/utils/is';

const getDefaultStorage = key => {
  if (!isSSR) {
    return sessionStorage.getItem(key);
  } else {
    return undefined;
  }
};

function useStorage(key, defaultValue) {
  const [storedValue, setStoredValue] = useState(getDefaultStorage(key) || defaultValue);

  const setStorageValue = value => {
    if (!isSSR) {
      sessionStorage.setItem(key, value);

      if (value !== storedValue) {
        setStoredValue(value);
      }
    }
  };

  const removeStorage = () => {
    if (!isSSR) {
      sessionStorage.removeItem(key);
    }
  };

  useEffect(() => {
    const storageValue = sessionStorage.getItem(key);

    if (storageValue) {
      setStoredValue(storageValue);
    }
  }, []);
  return [storedValue, setStorageValue, removeStorage];
}

export default useStorage;